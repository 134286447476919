<template>
    <p class="p-2 pr-4 bg-blue-50 text-theme-16 text-sm border-blue-200 flex gap-2 max-w-max rounded items-start">
        <InfoIcon class="flex-none" />
        <span class="break-words">
            Для добавления поисковых параметров, скопируйте из поисковой выдачи query-параметры и вставьте в поле
            <code>query</code>.<br />Пример:
            <code>
                https://kruizy-rossii.com/raspisanie-rechnyh-kruizov/?route_points=47&sort=departure_date_from&order=asc
            </code>
            <br />
            В поле <code>query</code> вставляем <code>?route_points=47&sort=departure_date_from&order=asc</code>
            <br />
            Если в таблице поля <code>query</code> нет, создайте его через кнопку "+", введя
            <strong>Название параметра</strong> - query
        </span>
    </p>
</template>

<script>
export default {
    name: 'SearchParamFillInfo',
};
</script>

<style scoped></style>
