<template>
    <div class="multiselect-table">
        <div class="table-wrapper bg-white">
            <table class="table">
                <thead>
                    <tr class="border-b-2">
                        <th></th>
                        <th v-for="header in headerLabels" :key="header">{{ header }}</th>
                        <th style="width: 46px">
                            <button type="button" class="btn btn-primary" @click="onAddRowClick">
                                <PlusCircleIcon class="stroke-current" />
                            </button>
                        </th>
                    </tr>
                </thead>
                <draggable
                    tag="tbody"
                    :list="modelValue"
                    :disabled="false"
                    class="list-group"
                    handle=".handle"
                    item-key="name"
                >
                    <template #item="{ element, index }">
                        <tr>
                            <td class="w-0 border-b dark:border-dark-5">
                                <MenuIcon class="handle box-content p-2" />
                            </td>
                            <td class="border-b dark:border-dark-5">
                                <Multiselect
                                    v-model="element.id"
                                    :track-by="trackBy"
                                    :label="trackBy"
                                    value-prop="id"
                                    placeholder="Выберите элемент"
                                    :options="multiselectOptions"
                                    @change="onOptionChanged"
                                />
                            </td>
                            <td class="border-b dark:border-dark-5">
                                <button type="button" class="btn border-0 shadow-none" @click="onRemoveRowClick(index)">
                                    <XCircleIcon />
                                </button>
                            </td>
                        </tr>
                    </template>
                </draggable>
            </table>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import Multiselect from '@vueform/multiselect';

export default {
    name: 'MultiselectTable',
    components: { draggable, Multiselect },
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
        draggable: Boolean,
        options: {
            type: Array,
            required: true,
        },
        trackBy: {
            type: String,
            default: 'title',
        },
        headerLabels: {
            type: Array,
            required: true,
        },
    },
    emits: ['update:modelValue', 'remove', 'add'],
    data() {
        return {
            multiselectOptions: [],
        };
    },
    watch: {
        modelValue: {
            handler() {
                this.updateNonSelectedIndexes();
            },

            immediate: true,
        },
    },
    methods: {
        updateNonSelectedIndexes() {
            this.multiselectOptions = this.options.map((option) => {
                const t = this.modelValue.find((item) => item.id === option.id);

                option.disabled = !!t;

                return option;
            });
        },

        onRemoveRowClick(index) {
            const modelValue = this.modelValue.filter((_, i) => i !== index);
            this.$emit('update:modelValue', modelValue);
        },
        onAddRowClick() {
            const modelValue = [...this.modelValue, { id: null }];
            this.$emit('update:modelValue', modelValue);
        },

        onOptionChanged() {
            this.$nextTick(() => {
                this.updateNonSelectedIndexes();
            });
        },
    },
};
</script>

<style scoped></style>
