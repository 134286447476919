<template>
    <input
        class="show-code form-check-switch"
        type="checkbox"
        :value="modelValue"
        @input="updateValue"
        @click="$emit('click')"
    />
</template>

<script>
export default {
    name: 'KoyuSwitcher',
    inheritAttrs: false,
    props: {
        modelValue: null,
    },
    created() {
        console.log('modelValue', this.modelValue);
    },
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
            this.$emit('onAction');
        },
    },
};
</script>

<style scoped>
.show-code {
    margin-right: 10px;
    top: 3px;
}
</style>
