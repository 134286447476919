<template>
    <div class="actions__container">
        <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" :to="{ name: 'page-create' }">
            Создать
        </router-link>
    </div>

    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-4">
            <ContentSearch />
        </div>
        <div class="col-span-4">
            <select v-model="categoryId" class="form-select form-select-lg" @change="fetchPages">
                <option value="">Все категории</option>
                <option v-for="option in categories" v-if="categories.length > 0" :key="option.id" :value="option.id">
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>

    <KoyuTable
        :loading="loading"
        :headers="tableHeaders"
        :table-rows="tableData"
        :table-actions="tableActions"
        :meta="pagination"
        @status="statusAction"
        @edit="editAction"
        @delete="deleteAction"
    />

    <PopupDeleteModal
        :is-open="modalDeleteOpen"
        :entity-name="modalDeleteCaption"
        :modal-data="modalDeleteRow"
        @update:isOpen="modalDeleteOpen = $event"
        @deleteModalAccepted="onDeleteModalAccepted"
    />

    <EditPageForm
        :title="modalFormTitle"
        :visible="modalFormVisible"
        :entity-id="entityId"
        @closeActionHandler="closeEditForm"
        @saveActionHandler="saveActionHandler"
        @saveOnlyActionHandler="saveOnlyActionHandler"
    />
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import KoyuTable from '@/components/tables/KoyuTable';
import EditPageForm from './EditPageForm';
import ContentSearch from '@/components/base/ContentSearch';

export default {
    name: 'Cruises',
    components: { ContentSearch, KoyuTable, EditPageForm, PopupDeleteModal },
    mixins: [errorResponse],
    async beforeRouteUpdate(to, from, next) {
        if (!from.query.id) {
            await this.fetchPages({ ...to.query });
        }
        next();
    },
    emits: ['saveActionHandler'],
    data() {
        return {
            loading: false,
            search: '',
            categoryId: '',
            modalFormTitle: 'Редактировать',
            modalFormVisible: false,
            modalDeleteOpen: false,
            categories: [],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'category_name', label: 'Категория' },
                { field: 'name', label: 'Название' },
                { field: 'header', label: 'Заголовок' },
                { field: 'full_url', label: 'Адрес' },
                { field: 'views', label: 'Просмотры' },
            ],
            tableActions: [
                {
                    name: 'edit',
                    title: 'редактировать',
                    icon: 'Edit2Icon',
                    callbackName: 'edit',
                },
            ],

            originalEntitiesList: [],
            pagination: null,

            entityId: null,

            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    computed: {
        tableData() {
            return this.originalEntitiesList.map((item) => {
                // добавляем ключи действий
                item.actions = [];
                // item.actions.push({
                //     type: 'checkbox',
                //     name: 'published',
                //     callbackName: 'status',
                // });
                item.actions.push({
                    type: 'button',
                    title: 'Редактировать',
                    callbackName: 'edit',
                    icon: 'Edit2Icon',
                });
                item.actions.push({
                    type: 'button',
                    title: 'Удалить',
                    classNames: 'bg-red-400',
                    callbackName: 'delete',
                    icon: 'TrashIcon',
                });
                return item;
            });
        },
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Страницы');
        Promise.all([this.fetchCategories(), this.fetchPages()]);
    },
    async mounted() {
        // open form if ID was presented
        if (this.$route.query && this.$route.query.id) {
            this.modalFormVisible = true;
            this.entityId = this.$route.query.id;
        }
    },
    methods: {
        async fetchCategories() {
            this.loading = true;
            try {
                const { data } = await this.axios.get('/page-categories');
                this.categories = data.data;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch categories: ' + ex);
            }
        },
        async fetchPages(params = { ...this.$route.query }) {
            this.loading = true;
            try {
                const { data } = await this.axios.get('/pages', {
                    params: {
                        ...params,
                        category_id: this.categoryId ? this.categoryId : null,
                    },
                });
                this.originalEntitiesList = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch pages: ' + ex);
            }
        },
        statusAction() {
            //     console.log('status is', row.published)
        },
        editAction(row) {
            this.modalFormVisible = true;
            this.entityId = row.id;
            this.$router.replace({
                path: this.$route.path,
                query: { ...this.$route.query, id: row.id },
            });
        },
        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `страницу "${row.name}"`;
            this.modalDeleteOpen = true;
        },
        async onDeleteModalAccepted(entity) {
            try {
                const { data } = await this.axios.delete('/pages/' + entity.id);
                if (data.success === true) {
                    this.$notify({
                        title: 'Готово',
                        text: `Страница "${entity.name}" удалена`,
                        type: 'primary', // 'success'
                    });
                    await this.fetchPages();
                }
            } catch (ex) {
                console.log(ex);
            }
            // TODO произошла ошибка
        },
        closeEditForm() {
            this.modalFormVisible = false;
            const { ...query } = this.$route.query;
            this.$router.push({ name: 'pages', query });
        },
        saveActionHandler() {
            this.closeEditForm();
            this.fetchPages();
        },
        saveOnlyActionHandler() {
            console.log('test');
            this.fetchPages();
        },
    },
};
</script>
