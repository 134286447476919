<template>
    <div class="simple-table">
        <div class="table-wrapper" :class="{ 'disabled-form': loading }">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <table class="table" :class="{ 'disabled-form': loading }">
                <thead>
                    <tr class="border-b-2">
                        <th
                            v-for="header in headers"
                            :key="header.field"
                            class="whitespace-nowrap header-action"
                            :class="{ active: header.field === sortField }"
                            @click.prevent="sort(header.field)"
                        >
                            <span
                                >{{ header.label }}
                                <ChevronDownIcon
                                    class="chevron-small"
                                    :class="{ active: header.field === sortField, up: currentSortOrder === 'desc' }"
                                />
                            </span>
                        </th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, rowIdx) in tableData" :key="rowIdx" class="bg-gray-200 dark:bg-dark-1 table-row">
                        <td
                            v-for="(header, headerIdx) in headers"
                            :key="headerIdx"
                            class="table-item border-b dark:border-dark-5"
                        >
                            <span v-if="header.raw" v-html="row[header.field]"></span>
                            <span v-else>{{ row[header.field] }}</span>
                        </td>
                        <td class="border-b dark:border-dark-5">
                            <div class="actions-container">
                                <template v-for="(action, actIdx) in row.actions" :key="actIdx">
                                    <template v-if="action.type === 'checkbox' && action.callbackName">
                                        <KoyuSwitcher
                                            :model-value="row[action.name]"
                                            :input="row[action.name]"
                                            @onAction="onAction(action.callbackName, row, rowIdx)"
                                        />
                                    </template>
                                    <template v-if="action.type === 'button' && action.callbackName">
                                        <a
                                            :key="actIdx"
                                            href="#"
                                            class="action-btn bg-primary-9"
                                            :class="[action.classNames]"
                                            :title="action.title"
                                            @click.prevent="onAction(action.callbackName, row, rowIdx)"
                                        >
                                            <component :is="action.icon" stroke="#fff" class="icon-small"></component>
                                        </a>
                                    </template>
                                </template>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination-wrapper">
            <Pagination
                v-if="meta && meta.total > meta.per_page"
                :current-page="meta.current_page"
                :last-page="meta.last_page"
                :range="10"
            />
        </div>
    </div>
</template>

<script>
import Preloader from '@/components/preloader/Main';
import Pagination from '@/components/pagination/Main';
import KoyuSwitcher from '@/components/base/KoyuSwitcher';

export default {
    name: 'KoyuTable',
    components: { Pagination, KoyuSwitcher, Preloader },
    props: {
        loading: {
            type: Boolean,
        },
        headers: {
            type: Array,
            required: true,
        },
        tableRows: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
        },
        inverseToggleIcons: Boolean,
    },
    data() {
        return {
            currentSortOrder: 'asc',
            sortField: null,
        };
    },
    computed: {
        tableData() {
            if (!(this.currentSortOrder && this.sortField)) {
                return this.tableRows;
            }
            return this.tableRows.sort((a, b) => {
                let modifier = 1;
                if (this.currentSortOrder === 'desc') modifier = -1;
                if (a[this.sortField] < b[this.sortField]) return -1 * modifier;
                if (a[this.sortField] > b[this.sortField]) return 1 * modifier;
                return 0;
            });
        },
    },
    methods: {
        sort(field) {
            if (field === this.sortField) {
                this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
            }
            this.sortField = field;
        },
        onAction(eventName, row, index) {
            this.$emit(eventName, row, index);
        },
    },
};
</script>

<style scoped lang="scss">
.table-wrapper {
    background-color: #fff;
}

.chevron-small {
    width: 20px;
    height: 20px;
    transition: 0.2s;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    &.up {
        transform: rotate(180deg);
    }
}

.icon-small {
    width: 15px;
    height: 15px;
}

.header-action {
    cursor: pointer;
    transition: 0.2s;

    span {
        display: block;
    }

    &.active {
        background: #fafafa;
    }
}

.table-row {
    transition: 0.2s;
    background: #fff;

    &:nth-child(odd) {
        background: #fafafa;
    }

    &:hover {
        background: #eee;
    }
}

.actions-container {
    display: flex;
}

.action-btn {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &.hidden {
        display: none;
    }

    &:last-child {
        margin-right: 0;
    }
}

@media (max-width: 1440px) {
    .table-item {
        padding: 12px 8px !important;
    }
    .header-action {
        padding: 12px 8px !important;
    }
}
</style>
