export const pointMixin = {
    data() {
        return {
            loading: false,
            form: {
                title: null,
                url: null,
                custom_image: null,
                custom_image_delete: null,
                description: null,
                weight: 0,
                lat: null,
                lon: null,
            },
        };
    },
};
