<template>
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-8">
            <input
                v-model="filters[searchQueryKey]"
                type="text"
                name="search"
                class="form-control form-control-lg w-full mb-4"
                :placeholder="placeholder"
                autocomplete="off"
                @input="onUpdate"
            />
        </div>
        <div class="col-span-4">
            <Multiselect
                v-model="filters[discountQueryKey]"
                :options="discountOptions"
                track-by="name"
                label="name"
                value-prop="id"
                placeholder="Выберите акцию"
                :hide-selected="false"
                :close-on-select="true"
                :searchable="true"
                @change="onUpdate"
            ></Multiselect>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

let searchTimer = null;

export default {
    name: 'CruiseContentSearch',
    components: { Multiselect },
    props: {
        placeholder: {
            type: String,
            default: 'Поиск',
        },
        searchQueryKey: {
            type: String,
            default: 'query',
        },
        discountQueryKey: {
            type: String,
            default: 'discount_id',
        },
        discountOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            filters: {
                [this.searchQueryKey]: null,
                [this.discountQueryKey]: null,
            },
        };
    },
    mounted() {
        this.fillSearchOnInit();
    },
    beforeUnmount() {
        searchTimer = null;
    },
    methods: {
        onUpdate() {
            clearTimeout(searchTimer);
            // TODO очему это сделано так? Это должно было вызывать событие наверх!
            searchTimer = setTimeout(() => {
                this.$router.push({ query: this.filters });
            }, 800);
        },
        fillSearchOnInit() {
            for (const [key, value] of Object.entries(this.$route.query)) {
                this.filters[key] = value;
            }
        },
    },
};
</script>

<style></style>
