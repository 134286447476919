<template>
    <CruiseContentSearch search-query-key="searchString" :discount-options="discounts" />

    <ContentTable
        :loading="loading"
        :headers="tableExample"
        :table-rows="tableData"
        :table-actions="tableActions"
        :meta="pagination"
        @edit="editAction"
        @delete="deleteAction"
    />

    <PopupDeleteModal
        :is-open="modalOpen"
        :entity-name="modalEntityName"
        :modal-data="modalData"
        @update:isOpen="modalOpen = $event"
        @deleteModalAccepted="onDeleteModalAccepted"
    />
</template>
<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import ContentTable from '@/components/tables/ContentTable';
import CruiseContentSearch from '@/components/cruises/CruiseContentSearch.vue';

export default {
    name: 'Cruises',
    components: { CruiseContentSearch, ContentTable, PopupDeleteModal },
    mixins: [errorResponse],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchCruises({ ...to.query });
        next();
    },
    data() {
        return {
            loading: false,
            modalOpen: false,
            tableExample: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название' },
                { field: 'ship_title', label: 'Корабль' },
                { field: 'departure_date_time', label: 'Время отправления' },
                { field: 'arrival_date_time', label: 'Время прибытия' },
            ],
            tableActions: [{ name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' }],
            addButtonDelete: false,
            tableData: [],
            pagination: null,
            modalEntityName: null,
            modalData: null,
            discounts: [],
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Круизы');
        await this.fetchCruises(this.$route.query);
    },
    methods: {
        async fetchCruises(searchString) {
            this.loading = true;

            try {
                const { data } = await this.axios.get('/cruises', { params: searchString });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.discounts = data.discounts;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch cruises: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalOpen = true;
        },
        editAction(row) {
            this.modalType = 'update';
            this.$router.push({ name: 'cruise-timetable', params: { cruise_id: row.id } });
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/cruises/' + data.id)
                .then((res) => {
                    this.fetchCruises(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>
