<template>
    <div>
        <div class="actions__container">
            <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" :to="{ name: 'page-category-create' }">
                Создать
            </router-link>
        </div>

        <ContentSearch />

        <ContentTable
            :loading="loading"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :table-actions="tableActions"
            @edit="editAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import ContentSearch from '@/components/base/ContentSearch';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';

export default {
    name: 'ListPageCategories',

    components: { ContentTable, ContentSearch, PopupDeleteModal },

    mixins: [errorResponse],

    async beforeRouteUpdate(to, from, next) {
        await this.fetchCategories({ ...to.query });
        next();
    },

    data() {
        return {
            loading: false,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'name', label: 'Название' },
                { field: 'slug', label: 'Ссылка' },
            ],
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            tableData: [],
            pagination: null,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
            // modalTitle: null,
            // modalCallback: null,
            // modalData: null,
        };
    },

    async created() {
        this.$store.commit('main/setPageHeader', 'Категории');
        await this.fetchCategories();
    },

    async mounted() {
        // open form if ID was presented
        if (this.$route.query && this.$route.query.id) {
            this.modalFormVisible = this.entityId = this.$route.query.id;
        }
    },

    methods: {
        async fetchCategories(params = { ...this.$route.query }) {
            this.loading = true;

            try {
                const { data } = await this.axios.get('/page-categories', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
            } catch (ex) {
                console.log('cant fetch categories: ' + ex);
            } finally {
                this.loading = false;
            }
        },

        editAction(row) {
            this.$router.push({ name: 'page-category-edit', params: { id: row.id } });
        },

        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `категорию "${row.name}"`;
            this.modalDeleteOpen = true;
        },

        async onDeleteModalAccepted(entity) {
            try {
                const { data } = await this.axios.delete('/page-categories/' + entity.id);
                if (data.success === true) {
                    this.$notify({
                        title: 'Готово',
                        text: `Категория "${entity.name}" удалена`,
                        type: 'primary', // 'success'
                    });

                    this.fetchCategories();
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
            // TODO произошла ошибка
        },
    },
};
</script>

<style scoped></style>
